import * as React from 'react';
import Lottie from 'lottie-react-web';
import ButtonViewState from './ButtonViewState';
import ButtonViewProps from './ButtonViewProps';
import ButtonViewStyle from './ButtonViewStyle';
import { Spring } from 'react-spring/renderprops';
import TranslationView from '../translation/TranslationView';

export default class ButtonView extends React.Component<ButtonViewProps, ButtonViewState>
{
    style = new ButtonViewStyle();

    /* CONSTRUCTOR */
    constructor(props: ButtonViewProps) {
        super(props);

        // State
        this.state = {
            isClicked: false,
            isHovered: false
        };

        // Binding
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnMouseIn = this.handleOnMouseIn.bind(this);
        this.handleOnMouseOut = this.handleOnMouseOut.bind(this);
        this.retrieveButtonStyle = this.retrieveButtonStyle.bind(this);
    }

    /* HANDLERS */
    handleOnClick() {
        if (!this.props.disabled) {
            this.setState({ isClicked: true });
            setTimeout(() => { (this.props.onClick as () => void)(); this.setState({ isClicked: false }); }, 300);
        }
    }

    handleOnMouseIn() {
        if (!this.props.disabled && !this.props.isBusy && !this.state.isClicked) {
            this.setState({ isHovered: true });
        }
    }

    handleOnMouseOut() {
        this.setState({ isHovered: false });
    }

    /* METHODS */
    retrieveButtonStyle() {
        const { isClicked, isHovered } = this.state;
        const { disabled, previous, width, inverted, hasWidthFull } = this.props;


        let style = {};

        if (width) {
            style = { width: width };
        } else if(hasWidthFull) {
            style = { width: "100%" };
        }

        if (disabled) {
            style = { ...style, ...this.style.disabledButton };
        }

        else if (previous) {
            style = { ...style, ...this.style.previousButton };
        }

        else {
            if(inverted){
                style = { ...style, ...this.style.buttonInverted };
            } else {
                style = { ...style, ...this.style.button };
            }
        }

        if (isClicked && !inverted) {
            return { ...style, transform: 'scale(1.05)', filter: 'brightness(100%)', };
        }

        if (isHovered) {
            if(inverted){
                return { ...style, ...this.style.button };
            } else {
                return { ...style, filter: 'brightness(90%)' };
            }
        }

        return style;
    }

    retrieveChildButtonStyle(style: any) {
        if(this.props.disabled){
            if(this.props.inverted){
                return { ...this.style.buttonInverted, ...style };
            } else {
                return { ...this.style.button, ...style } }
        } else {
            return {...this.style.disabledButton, ...style }
        }
    }

    /* RENDERING */
    render() {
        return (
            <Spring from={this.retrieveButtonStyle()} to={this.retrieveButtonStyle()}>
                {(style) =>
                    <div onClick={this.handleOnClick} onMouseOver={this.handleOnMouseIn} onMouseLeave={this.handleOnMouseOut} style={this.retrieveChildButtonStyle(style)}>
                        {this.props.isBusy === true ?
                            (
                                <Lottie
                                    options={{
                                        animationData: require('./assets/loading.json')
                                    }}
                                />
                            )
                            :
                            (
                                <TranslationView style={{ color: this.props.inverted && !this.state.isHovered ? "#009DC8" : 'white', fontWeight: 600 }} children={this.props.children} />
                            )
                        }
                    </div>
                }
            </Spring>
        );
    }
}