import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import EditCheckDialogView from "./components/EditCheckDialog/EditCheckDialogView";
import EditCheckDialogViewState from "./components/EditCheckDialog/EditCheckDialogViewState";
import EditCheckViewState from "./EditCheckViewState";
import Service from "../../../../../network/Service";
import Application from "../../../../Application";

export default class EditCheckView extends Component<SharedViewProps, EditCheckViewState> {

    service = new Service();

    state = {
        queryParams: new URLSearchParams(this.props.routeProps.location.search),
        errors: {}
    };

    render() {
        return (
            <EditCheckDialogView onSubmit={this.onSubmit} errors={this.state.errors} />
        );
    }

    onSubmit = async (formData: EditCheckDialogViewState, done: () => void) => {
        if (formData.selectedReasonId === undefined || formData.selectedReasonId === -1) {
            done();
            this.setState(prev => {
                prev.errors['reason'] = 'errorRequiredField'
                return prev;
            })
        }

        else {
            const checkId = this.state.queryParams.get('checkId');
            const superiorId = this.state.queryParams.get('superiorId');

            if (checkId && superiorId) {
                const { error } = await this.service.editCheck(checkId, {
                    superiorId: superiorId,
                    reason: formData.selectedReasonId,
                    comment: formData.comment
                });

                if (!error) {
                    this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                        origin: 'edit-check'
                    });
                }

                else {
                    done();
                    this.setState(prev => {
                        prev.errors['reason'] = undefined;
                        prev.errors['form'] = 'genericError';
                        return prev;
                    });
                }
            }
        }
    }
}