import { CSSProperties } from "react";

export default class DialogMultilineTextInputViewStyle {

    textField: CSSProperties = {
        marginTop: 10,
        fontWeight: 300,
        fontFamily: 'Roboto',
    }

    mobileTextField: CSSProperties = {
        marginTop: 10,
        fontWeight: 300,
        fontFamily: 'Roboto',
        maxHeight: 82,
        marginBottom: 48,
    }

    input: CSSProperties = {
        fontSize: 15,
        color: '#405364',
        fontWeight: 300,
        fontFamily: 'Roboto'
    };
}