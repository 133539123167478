export default class ButtonViewStyle {
    boxFooter: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 30
        }

    button: React.CSSProperties =
        {
            height: 35,
            minWidth: 100,
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 500,
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: "rgb(26, 165, 207)",
            borderRadius: 8,
            transform: 'scale(1)',
            filter: 'brightness(100%)'
        }

    buttonInverted : React.CSSProperties =
    {
        ...this.button,
        backgroundColor: 'white',
        border: '1px solid rgb(26, 165, 207)',
    }

    disabledButton: React.CSSProperties =
        {
            ...this.button,
            backgroundColor: "#D6DCE5"
        }

    previousButton: React.CSSProperties =
        {
            ...this.button,
            backgroundColor: '#D6DCE5'
        }

    previous: React.CSSProperties =
        {
            width: 120
        }
}