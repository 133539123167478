import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import CheckConfirmationView from "./components/CheckConfirmation/CheckConfirmationView";
import NoAvailabilityView from './components/NoAvailability/NoAvailabilityView';
import TranslationView from "../../../../components/translation/TranslationView";
import CheckEditConfirmationView from "./components/CheckEditConfirmation/CheckEditConfirmationView";
import CheckCancellationConfirmationView from "./components/CheckCancellationConfirmation/CheckCancellationConfirmationView";
import GSMProblemConfirmationView from "./components/GSMProblemConfirmation/GSMProblemConfirmationView";
import CheckRelaunchConfirmationView from "./components/CheckRelaunchConfirmation";

export default class SubmitSuccessView extends Component<SharedViewProps> {

    render() {
        let { state } = this.props.routeProps.location;
        
        if (state.origin === 'confirm-availability' && state.checkDate) {
            return (
                <CheckConfirmationView checkDate={state.checkDate} checkTime={state.checkTime} />
            );
        }

        else if (state.origin === 'deny-availability') {
            return (
                <NoAvailabilityView />
            );
        }

        else if (state.origin === 'edit-check') {
            return (
                <CheckEditConfirmationView />
            );
        }

        else if (state.origin === 'cancel-check') {
            return (
                <CheckCancellationConfirmationView />
            );
        }

        else if (state.origin === 'relaunch-check') {
            return (
                <CheckRelaunchConfirmationView />
            );
        }

        else if (state.origin === 'gsm-problem') {
            return (
                <GSMProblemConfirmationView />
            );
        }

        else {
            return (
                <TranslationView>
                    genericError
                </TranslationView>
            )
        }
    }
}