import { createMuiTheme } from '@material-ui/core';

export default class ApplicationStyle {
    theme = createMuiTheme({
        overrides: {
            MuiFormControl: {
                root: {
                    width: '100%',
                    fontWeight: 200
                },
            },
            MuiInputLabel: {
                root: {
                    fontWeight: 300
                },
                error: {
                    color: '#EC5467'
                }
            },
            MuiOutlinedInput: {
                notchedOutline: {
                    borderRadius: 15
                }
            },
            MuiInput: {
                underline: {
                    error: {
                        color: '#EC5467',
                        '&:after': {
                            borderBottomColor: '#EC5467 !important'
                        }
                    }
                }
            },
            MuiSelect: {

            }
        },
        palette: {
            primary: {
                main: 'rgb(26, 165, 207)',
                dark: 'rgb(26, 165, 207)'
            },
            action: {
                hover: "#9FD6E5",
                selected: "#9FD6E5"
            },
            error: {
                main: '#EC5467'
            }
        }
    });

    shell: React.CSSProperties = {
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${require('./assets/background.png')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden'
    }

    mobileShell: React.CSSProperties = {
        height: window.innerHeight,
        width: window.innerWidth,
        backgroundImage: `url(${require('./assets/background.png')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden',
    }
}