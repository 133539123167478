import { CSSProperties } from "react";

export default class DialogSelectViewStyle {

    control : CSSProperties = {
        marginBottom : 20
    }

    placeholder : CSSProperties = {
        color : '#405364',
        opacity : 0.4,
        fontSize : 18
    }
}