import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import GSMProblemDialogView from "./components/GSMProblemDialog/GSMProblemDialogView";
import GSMProblemDialogViewState from "./components/GSMProblemDialog/GSMProblemDialogViewState";
import GSMProblemViewState from "./GSMProblemViewState";
import Service from "../../../../../network/Service";
import Application from "../../../../Application";

export default class GSMProblemView extends Component<SharedViewProps, GSMProblemViewState> {

    service = new Service();

    state = {
        errors: {},
        queryParams: new URLSearchParams(this.props.routeProps.location.search)
    };

    render() {
        return (
            <GSMProblemDialogView errors={this.state.errors} onSubmit={this.onSubmit} resetErrors={this.resetErrors} />
        );
    }

    onSubmit = async (formData: GSMProblemDialogViewState, done: () => void) => {
        this.setState({ errors: {} });

        const superiorId = this.state.queryParams.get('superiorId');
        const checkId = this.state.queryParams.get('checkId');
        const { gsmNumber, selectedAction } = formData;

        if (!superiorId || !checkId) {
            this.setState(prev => {
                prev.errors['form'] = 'genericError';
                return prev;
            });
            done();
            return;
        } else if (!gsmNumber && selectedAction === undefined) {
            done();
            this.setState(prev => {
                prev.errors['gsmNumber'] = 'errorRequiredField'
                prev.errors['action'] = 'errorRequiredField'
                return prev;
            })
        }

        // Technically valid state as a value has been entered in only one of the fields
        else {
            const { error } = await this.service.reportGSMProblem(checkId, {
                superiorId: superiorId,
                gsmNumber: gsmNumber,
                action: selectedAction
            });

            if (!error) {
                this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                    origin: 'gsm-problem'
                });
            }

            else {
                this.setState(prev => {
                    prev.errors['form'] = 'genericError';
                    return prev;
                });
                done();
            }
        }
    }

    resetErrors = () => {
        this.setState({ errors: {} });
    }
}