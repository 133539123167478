import React, { Component } from "react";
import DialogBaseView from "../../../../components/DialogBase/DialogBaseView";
import DialogSelectView from "../../../../components/DialogBase/components/DialogSelect/DialogSelectView";
import { MenuItem } from "@material-ui/core";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DialogMultilineTextInputView from "../../../../components/DialogBase/components/DialogMultilineTextInput/DialogMultilineTextInputView";
import EditCheckDialogViewState from "./EditCheckDialogViewState";
import EditCheckDialogViewProps from "./EditCheckDialogViewProps";
import CheckEditReason from "../../../../../../../models/constants/CheckEditReason";
import { getKeys } from '../../../../../../../lib/Enums';

export default class EditCheckDialogView extends Component<EditCheckDialogViewProps, EditCheckDialogViewState> {

    state = {
        selectedReasonId : -1,
        comment : ''
    };

    render() {
        const { errors } = this.props;

        return (
            <DialogBaseView title='editOrCancelCheck' subtitle='editCheck' onSubmit={this.onSubmit} error={errors.form}>
                <DialogSelectView placeholder='whatToModifyAboutCheck' value={this.state.selectedReasonId} onChange={this.onReasonChange} error={errors.reason}>
                    {getKeys(CheckEditReason).map((reason, index) => (
                        <MenuItem key={index} value={index}>
                            <TranslationView>
                                {reason}
                            </TranslationView>
                        </MenuItem>
                    ))}
                </DialogSelectView>

                <DialogMultilineTextInputView 
                    rows={4}
                    placeholder='addComment'
                    onChange={this.onCommentChange} />
            </DialogBaseView>
        );
    }

    onReasonChange = (value : number, _? : string) => {
        this.setState({
            selectedReasonId : value
        });
    }   

    onCommentChange = (comment : string) => {
        this.setState({ comment });
    }

    onSubmit = (done : () => void) => {
        this.props.onSubmit(this.state, done);
    }
}