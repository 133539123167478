import * as React from 'react';
import { Component } from "react";
import { Switch, Route } from "react-router-dom";
import ConfirmAvailabilityView from './modules/ConfirmAvailability/ConfirmAvailabilityView';
import SharedViewProps from '../../shared/SharedViewProps';
import DenyAvailabilityView from './modules/DenyAvailability/DenyAvailabilityView';
import SubmitSuccessView from './modules/SubmitSuccess/SubmitSuccessView';
import EditCheckView from './modules/EditCheck/EditCheckView';
import CancelCheckView from './modules/CancelCheck/CancelCheckView';
import GSMProblemView from './modules/GSMProblem/GSMProblemView';
import Application from '../../Application';
import RelaunchCheckView from './modules/RelaunchCheck';
import AlreadyBookedCheckView from './modules/AlreadyBookedCheck/AlreadyBookedCheckView';

export default class FormsView extends Component<SharedViewProps> {

    render() {
        const { match } = this.props.routeProps;
        Application.language = this.props.routeProps.match.params.language;


        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/confirm-availability`} render={routeProps => <ConfirmAvailabilityView routeProps={routeProps} />} />
                    <Route path={`${match.url}/deny-availability`} render={routeProps => <DenyAvailabilityView routeProps={routeProps} />} />
                    <Route path={`${match.url}/edit-check`} render={routeProps => <EditCheckView routeProps={routeProps} />} />
                    <Route path={`${match.url}/cancel-check`} render={routeProps => <CancelCheckView routeProps={routeProps} />} />
                    <Route path={`${match.url}/relaunch-check`} render={routeProps => <RelaunchCheckView routeProps={routeProps} />} />
                    <Route path={`${match.url}/gsm-problem`} render={routeProps => <GSMProblemView routeProps={routeProps} />} />
                    <Route path={`${match.url}/submit-success`} render={routeProps => <SubmitSuccessView routeProps={routeProps} />} />
                    <Route path={`${match.url}/already-booked-check`} render={routeProps => <AlreadyBookedCheckView routeProps={routeProps} />} />
                </Switch>
            </div>
        );
    }
}