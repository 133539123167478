import React, { Component } from "react";
import CheckDialogViewProps from './DialogBaseViewProps';
import CheckDialogStyle from './DialogBaseViewStyle';
import DialogHeaderView from "./components/DialogHeader/DialogHeaderView";
import DialogFooterView from "./components/DialogFooter/DialogFooterView";
import { isMobile } from "react-device-detect";

export default class DialogBaseView extends Component<CheckDialogViewProps> {

    private _style = new CheckDialogStyle(); 

    render() {
        return (
            <div style={isMobile ? this._style.mobileContainer : this._style.container}>
                <DialogHeaderView {...this.props} />

                <div style={this._style.content}>
                    {this.props.children}
                </div>

                <DialogFooterView error={this.props.error} onSubmit={this.props.onSubmit} hasLeftButton={this.props.hasLeftButton} onSubmitLeftButton={this.props.onSubmitLeftButton} titleLeftButton={this.props.titleLeftButton}/>
            </div>
        );
    }
}